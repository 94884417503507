import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

const NIS = ({ data }) => {
  const NISData = data.allDatoCmsNisModel.edges
  const [NISState, setNISState] = useState(() => [
    true,
    ...new Array(data.allDatoCmsNisModel.edges.length).fill(false),
  ])

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/nis")
    }
  }, [])

  function NISClick(index) {
    setNISState(previousState => {
      return previousState.map((element, i) => (i === index ? !element : false))
    })
  }

  return (
    <React.Fragment>
      <PageTitle title={"NIS 2 & DORA"} />
      <Layout>
        <div className="gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>NIS 2 & DORA</a>
              </li>
            </ul>
          </div>
          <div className="asked-question">
            <h1>
              Alles über <span>NIS 2 & DORA</span>
            </h1>
            <div className="asked-question__block" style={{ marginTop: '11em' }}>
              <div className="asked-question__accordion">
                {NISData.map((nis, index) => {
                  nis = nis.node
                  return (
                    <div
                      className={`asked-question__accordion--box${NISState[index] ? " active" : ""
                        }`}
                      key={index}
                    >
                      <div
                        className="asked-question__accordion--name js-accordion__name"
                        onClick={() => NISClick(index)}
                      >
                        {nis.question}
                        {NISState[index] ? (
                          <svg
                            width="43"
                            height="44"
                            viewBox="0 0 43 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: 43, minWidth: 43, maxWidth: 43 }}
                          >
                            <path
                              d="M10.4237 32.4237L31.6369 11.2105M10.9723 11.0277L32.1855 32.2409"
                              stroke="#0B67F0"
                              strokeWidth="2"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: 30, minWidth: 30, maxWidth: 30 }}
                          >
                            <path
                              d="M0 14.7414H30M15.5172 0L15.5172 30"
                              stroke="#0B67F0"
                              strokeWidth="2"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="asked-question__accordion--body">
                        <div
                          className="asked-question__accordion--body--content"
                          dangerouslySetInnerHTML={{ __html: nis.answer }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default NIS

export const query = graphql`
  {
    allDatoCmsNisModel(
      filter: { locale: { eq: "de-DE" } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`
